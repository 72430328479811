import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import * as styles from "../styles/home.module.css"
import { Helmet } from "react-helmet"
export default function Home() {
  return (
    <Layout>
      <section className={styles.header}>
        <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Home</title>
          <link rel="canonical" href="http://mkrilanovich.com/" />
        </Helmet>
          <h2>Design</h2>
          <h3>Develop</h3>
          <p>Software Engineer & Data Engineer</p>
          <Link className={styles.btn} to="/projects">
            My Portfolio Projects
          </Link>
        </div>
      </section>
    </Layout>
  )
}
